/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '../css/app-styles'

// START polyfills for IE
import Promise from 'promise-polyfill'
import Toasted from 'vue-toasted';
import URLSearchParams from 'url-search-params'
import objectFitImages from 'object-fit-images'

if (!window.Promise) {
  window.Promise = Promise
}

if (!window.URLSearchParams) {
  window.URLSearchParams = URLSearchParams
}
// END polyfills for IE

// jQuery required automatically - see webpack config
require('jquery-ujs') // for Rails helpers (e.g. link_to with method: :delete)

// START Vue error messages
import Vue from 'vue/dist/vue.esm'
import VeeValidate from 'vee-validate/dist/vee-validate'

window.VeeValidate = VeeValidate

Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: {
    en: {
      messages: {
        required: () => 'required',
        regex: () => 'invalid format',
        numeric: () => 'invalid format',
        email: () => 'must be a valid email'
      }
    }
  }
});
// END Vue error messages

// open external links in new window 
addEventListener("click", function(event) {
  var el = event.target

  if (el.tagName === "A") {
    if (!el.isContentEditable && isExternal(el)) {
      el.setAttribute("target", "_blank")
    }
  } else if (el.parentElement.tagName === "A") {
    if (!el.isContentEditable && isExternal(el.parentElement)) {
      el.parentElement.setAttribute("target", "_blank")
    }
  }
}, true)

function isExternal(el) {
  if (el.host && el.host !== window.location.host)  {
    return true
  } else {
    return false
  }
}

// always open file attachments in education pages (attached via Trix in the CMS) in new window

$(document).ready(function() {

  // Typekit
  try{Typekit.load({ async: true });}catch(e){}
  
  // always open file attachments in education pages (attached via Trix in the CMS) in new window
  $("a[data-trix-attachment]").attr("target", "_blank");
  objectFitImages(); //IE polyfill for object-fit: https://github.com/bfred-it/object-fit-images

  // handle flash messages via vue-toasted
  Vue.use(Toasted);
  setTimeout(function() {
    $(".callout").children().each(function (i, child) {
      Vue.toasted.show(child.innerHTML,
        {
          duration: 4000,
          action: {
            text: 'x',            
            onClick: (e, toastObject) => { toastObject.goAway(0); }
          }
        }
      );
    });
  }, 100);
});

// mobile site nav
$(function() {
  $('.hamburger').on('click', (event) => {
    event.target.classList.toggle('is-active');
    $('.header')[0].classList.toggle('is-active');
  })
})
